import $ from 'jquery';
import Cookies from 'js-cookie';

export function pharmacistFavoriteCount() {
  let count = '0'
  if(Cookies.get('_fp')){
    count = Cookies.get('_fp').split(',').length.toString()
  }
  var targetJsFpCount = document.getElementsByClassName('js_header_favorite_count')
  if(targetJsFpCount.length > 0){ $(targetJsFpCount).html(count) }
}

export function touhanFavoriteCount() {
  let count = '0'
  if(Cookies.get('_touhan_fp_ids')){
    count = Cookies.get('_touhan_fp_ids').split(',').length.toString()
  }
  var targetJsFpCount = document.getElementsByClassName('js-touhan-fp-count')
  if(targetJsFpCount.length > 0){ $(targetJsFpCount).html(count) }
}
